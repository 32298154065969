<template>
	<div>
		<div v-if="!played" class="game-title">
			<img class="game-title-bgimg" alt="game.icon" :src="game.thumb">
			<div class="game-box-box flex-center">
				<div class="game-box" :style="'background-image: url('+ game.thumb +');'"></div>
				<p class="game-title-name">{{game.title}}</p>
			</div>
			<p style="margin: 0px; color: #ffffff; position: relative; font-size: 10px; text-align: center;">
				Advertisement ▼</p>
		</div>
		<div v-if="!played" class="game-icon-play flex-center" @click="played=true">
			<a class="game-icon-now btn-big-color"> PLAY NOW </a>
		</div>
		<div v-if="!played" class="load-description">
			<h2 class="game-desc-title" style="margin: 0px;">Description</h2>
			<div class="game-desc-text">
				<p>{{game.description}}</p>
			</div>
			<h3 class="game-desc-title" style="margin: 10px 0px;">Categories</h3>
			<div style="display: flex; flex-wrap: wrap;">
				<div class="game-cate-bottom" v-for="tag in gameTags" :key="tag">
					<p style="margin: 0px;">{{tag}}</p>
				</div>
			</div>
		</div>
		<div class="mo-grids" v-if="!played">
			<game-item v-for="item in moreGames" :game="item" :key="item.handle" :showTitle="false" height="110"
				style="height: 100%; width: 100%; display: flex; border: none; padding: 0px; box-shadow: rgba(50, 50, 50, 0.65) 0px 1px 3px 0px;">
			</game-item>
		</div>
		<div v-if="played" class="iframe-box">
			<iframe :src="game.url" frameborder="0" name="ifr" class="iframe"></iframe>
			<div class="iframe-games">
				<a v-for="item in moreGames" :key="item.handle" :href="'/game/'+item.handle" class="iframe-game">
					<img :src="item.thumb" :alt="item.title"></a>
			</div>
			<div></div>
		</div>
		<a v-if="played" @click="played=false">
			<img
				src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAOpSURBVHgBtZfPTxNREMfnPZYFtUAlQSFpCQgkBkxoSDRBTYATHiRw4qbUmyYtLX8B8g9IoRiPFDzJCUJi4IQkojFeSiInQBtaBSXBBQqxZdnnTKFNC6Xd/uCbQJru9n125s3M+y4DHRp3/jAGQbIwwbqFgHYQUINfG08vK/jnZRy8gmmLdlf1tJ41WVqgJjtACGelqdBobpCh0iyDoZSDXHTy03BIwM4fFXa2VfCvhuF34MgnGPNInE+8cFX5Mga7+38hUHtZ11RktNy/irAC0KPg3jF4Px3C95WQTwMY6nebPLrBo47AcElJgbOjuwTKKyTIRvQA81N7cLB/7LKNmAbSgkftP8frm2TrvQ5DLJ3ZirZhaS4I/rWQx+Y2PYu/lpA/irS6Tn7e9rgUCqTcoJHFcY3a20UUvaW1zmF8/+XVfPQaj0HtASul9+GjkrQLUjHNT+2CXt3F7F3DtZHhTAC/cW7WcAaDnb2ladO7vhKC2bcKRqGBXtGaHd2ltK+Dw9gpMbCqaX23Gotq0lUuQT/O7UP5DQm6nhghE5VXFAB1iKRJzhiYgbBSy+iFdvaWZVV4EYZgDoqau20bPTdNhSmjXca+zBVKIgYNIkmVLBw4azfXySmh3s+HlKZIenNtMZp+mOcejqE3UyTpoHqqXY8wYvzP2miPLcnAlwElGcpwS/GQIaLxbPqiUPr+ANsmk569SOZ6GRpbrkS3ypg0x4XFJ3OFRh4+XX50Zh0CKwhIiLqxpRjC/zRYxqgNZRwe5DHVkWCQyRkDX3D3+NwN1HPNrVdhDft3CVspX6JxiyF6uQCxuOU/SnrTZcDJMAgmljloMO1fD194Yzx8dvJvNFVZi1wKcPGBq5LqxYiVZOmOh9M+72zj4f5OyRpOjE20RuTL+ICrVgEmRqiQUqke+zlXODGYEBP0+eR04qoLU6nQxuuFz04qkIko2lX0YaokeWJgilpoMLQws582EoJ3Pb0OF43ZZKI1yX+hhgZOnWfCyHLb/K76O8WOfPYtaWFmDzZWQyP2MXPMgZw7aty2gKe6Qe4jeD7M3teFIKx9C03Yx0zW+GtJV6bIDWWSg6xQZKhnIaoX2rrgrpoQaUowicwfXh1sQEtEfaz3AaiIqHqxkBSOdWN7bXIluy9lLofRBEqqahWc9VWhS6EThgw+FVb8KwzBtgJH6J/DsIkzAVtmBOeDK9KqF0j3JpJFIreCL27N6C8t7PSlTUQGPvho9NIUpIGUChjVf5Nhwse8N6C8AAAAAElFTkSuQmCC"
				alt="home" class="iframe-back"></a>
	</div>
</template>

<script>
	import GameItem from '@/components/common/game-item'
	export default {
		props: {
			game: {
				type: Object,
				required: true
			},
			games: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				played: false
			}
		},
		components: {
			GameItem
		},
		computed: {
			moreGames() {
				return this.games.slice(0, 18)
			},
			gameTags() {
				return this.game.tags.split(',').map(v => v.trim()).slice(0, 8)
			}
		}
	}
</script>
<style lang="scss" scoped>
	.game-box-box {
		position: relative;
		overflow: hidden;
		height: 200px;
		margin: 10px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-moz-box-orient: vertical;
		-moz-box-direction: normal;
		flex-direction: column;
	}

	.game-box {
		width: 120px;
		height: 120px;
		border: 2px solid #fff;
		border-radius: 6px;
		overflow: hidden;
		margin-bottom: 15px;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: cover;
		margin-top: 30px;
	}

	.load-description {
		margin: 0 10px;
		height: auto;
		padding: 10px;
		box-shadow: rgba(50, 50, 50, 0.65) 0px 1px 3px 0px;
	}

	.game-title {
		margin: 0 10px;
		border-radius: 10px;
		position: relative;
		overflow: hidden;
		box-shadow: 0 1px 3px 0 rgba(50, 50, 50, .65);
		padding-bottom: 20px;
	}

	.game-title-bgimg {
		-webkit-filter: blur(20px);
		filter: blur(20px);
		width: 100%;
		height: 100%;
		position: absolute;
		background-color: transparent;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: cover;
	}

	.game-icon-play {
		cursor: pointer;
		margin: 15px 10px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-moz-box-pack: center;
		justify-content: center;
	}

	.game-icon-now {
		font-weight: 700;
		display: block;
		text-align: center;
		height: 40px;
		line-height: 40px;
		width: 40%;
		border-radius: 40px;
		border: 2px solid #fff;
		box-shadow: rgba(50, 50, 50, 0.65) 0px 1px 3px 0px;
	}

	.game-title-name {
		text-align: center;
		font-size: 16px;
		font-weight: 400;
		color: #fff;
		margin: 0;
	}

	.mo-grids {
		margin-bottom: 50px;
	}

	.iframe-games {
		overflow-x: auto;
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: flex;
		padding: 2px 4px;
	}

	.iframe-game {
		overflow: hidden;
		-webkit-box-flex: 0;
		-webkit-flex: none;
		-moz-box-flex: 0;
		flex: none;
		width: 70px;
		height: 70px;
		margin: 4px;
		border-radius: 6px;
	}

	.iframe-box {
		position: fixed;
		top: 48px;
		left: 0;
		right: 0;
		bottom: 0;
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-moz-box-orient: vertical;
		-moz-box-direction: normal;
		flex-direction: column;
		background-color: #fff;
	}

	.iframe {
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-moz-box-flex: 1;
		flex: 1;
		display: block;
		max-width: 540px;
		width: 100%;
		margin: auto;
	}

	.iframe-back {
		position: absolute;
		z-index: 4;
		left: 0;
		top: 56px;
		width: 40px;
		height: 40px;
	}
</style>