<template>
	<div class="grid-all block-row-f">
		<div class="block-grid block-grid-f">
			<div class="grid-load">
				<div class="load-description">
					<h2 class="game-desc-title" style="margin: 0px;">Description</h2>
					<h3 style="margin: 15px 0px 5px;">{{game.title}}</h3>
					<div class="game-desc-text">
						<p class="game-desc-text-p">{{game.description}}</p>
					</div>
					<h3 class="game-desc-title" style="margin: 15px 0px 5px;">Categories</h3>
					<div style="display: flex; flex-wrap: wrap;">
						<div class="game-cate-bottom" v-for="tag in gameTags" :key="tag">
							<p style="margin: 0px;">{{tag}}</p>
						</div>
					</div>
				</div>
			</div>
			<game-item v-for="item in otherGames" :game="item" :key="item.handle" :showTitle="false" height="114"></game-item>
		</div>
	</div>
</template>

<script>
	import GameItem from '@/components/common/game-item'
	export default {
		props: {
			game: {
				type: Object,
				required: true
			},
			games: {
				type: Array,
				default: () => []
			}
		},
		components: {
			GameItem
		},
		computed: {
			otherGames() {
				return this.games.slice(-26)
			},
			gameTags() {
				return this.game.tags.split(',').map(v => v.trim()).slice(0, 8)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.grid-load {
		overflow: hidden;
		grid-area: 1/3/span 2/span 5;
	}

	.game-desc-text-p {
		display: block;
		height: 75px;
		overflow: auto;
	}

	.game-desc-text-p::-webkit-scrollbar {
		width: 5px;
	}

	.game-desc-text-p::-webkit-scrollbar-thumb {
		background: #fff;
		border-radius: 5px;
	}

	.game-desc-text-p::-webkit-scrollbar-track {
		background: #0085c7;
		border-radius: 5px;
	}
</style>