<template>
	<div class="pbody-game">
		<loading v-if="game === undefined"></loading>
		<game-pc-top v-if="game !== undefined && !isMobileLayout" :game="game" :games="shuffleGames" />
		<game-pc-bottom v-if="game !== undefined && !isMobileLayout" :game="game" :games="shuffleGames" />
		<game-mobile v-if="game !== undefined && isMobileLayout" :game="game" :games="shuffleGames" />
	</div>
</template>

<script>
	import Loading from '@/components/common/loading'
	import GamePcTop from '@/components/game/game-pc-top'
	import GamePcBottom from '@/components/game/game-pc-bottom'
	import GameMobile from '@/components/game/game-mobile'
	import {mapState} from 'vuex'
	export default {
		inject: ['app'],
		components: {
			Loading,
			GamePcTop,
			GamePcBottom,
			GameMobile
		},
		computed: {
			...mapState({
				games: state => state.games.games
			}),
			shuffleGames() {
				return this.games.filter(v => v.handle !== this.$route.params.id).sort(() => Math.random() - 0.5)
			},
			game() {
				return this.games.find(v => v.handle === this.$route.params.id)
			},
			isMobileLayout() {
				return this.app.isMobileLayout
			}
		},
		watch: {
			games(newVal, oldVal) {
				if (newVal.length > 0) {
					const existed = newVal.find(v => v.handle === this.$route.params.id)
					if (existed === undefined) {
						this.$router.push('/')
					}
				}
			}
		}
	}
</script>

<style scoped>
	.pbody-game {
		margin: 10px auto 0 auto;
		max-width: 1385px;
		width: 100%;
	}
</style>